import nimbuzzLogo from "./../assets/nimbuzz.png";
export const TitleHeader = () => {
  return (
    <header>
      <div className="page-account-detail">
        <nav className="navbar navbar-expand-lg navbar-light navbar-class">
          <a className="navbar-brand" href="/">
            <img src={nimbuzzLogo} width="150" className="m-1" />
          </a>
        </nav>
      </div>
    </header>
  );
};
