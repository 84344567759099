import React, { useEffect, useState } from "react";
import { BidList } from "../Components/bidLIst";
import { Bid } from "../Components/bid";
import axios from "../axios";
import Pagination from "@mui/material/Pagination";

export const BidName = () => {
  const [selectedName, setSelectedName] = React.useState({});
  const [nameList, setNameList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [currency, setCurrency] = useState("");
  React.useEffect(() => {
    axios.get(`api/v3/names/name-list?page=${page}&limit=${12}`).then((res) => {
      setNameList(res.data.data.list_data);

      setCount(res.data.data.count);
    });

    axios.get("/api/v3/names/amount-multiplier").then((res) => {
      setCurrency(res.data.currency);
      // setCountryCode(res.data.currency === "INR" ? "+91" : "+977");
    });
  }, [selectedName, page]);
  const viewList = (name) => {
    setSelectedName({ ...name, list: true });
  };
  const bid = (name) => {
    setSelectedName({ ...name, bid: true });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {}, [selectedName]);
  return (
    <div className="main-wrapper">
      <div className="main-banner">
        <div className="container-fluid mt-2 p-5 ">
          <h3>Bid Name</h3>

          <p className="font-weight-light">List of bid names</p>
          <div className="row mt-2">
            <Pagination count={count} page={page} onChange={handlePageChange} />

            {nameList.map((name, index) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-3 p-2" key={index}>
                  <div className="bid-list-card border p-2 d-flex flex-column">
                    <div className="w-100">
                      <h5 className="p-1 font-weight-bold">{name.name}</h5>
                    </div>
                    <div className="w-100 mt-1">
                      <span className="font-weight-light p-1 font-weight-light">
                        Total Bids :
                      </span>
                      <span className="font-weight-light p-1">
                        {name.bidCount}
                      </span>
                    </div>
                    <div className="w-100 mt-1">
                      <span className="font-weight-light p-1 ">
                        Highest Bid :
                      </span>
                      <span className="font-weight-light p-1">
                        {currency === "NPR"
                          ? name.highestBid
                          : name.highestBid / 1.6}{" "}
                        {currency}
                      </span>
                    </div>
                    <div className="w-100 mt-1">
                      <span className="font-weight-light p-1 font-weight-light">
                        Bid Expires In :
                      </span>
                      <span className="font-weight-light p-1">
                        <TimerCountdown date={name.expireAt}></TimerCountdown>
                      </span>
                    </div>
                    <div className="w-100 mt-4 d-flex justify-content-between">
                      <button
                        className="btn btn-text btn-bid m-0 font-weight-light border"
                        onClick={() => {
                          viewList(name);
                        }}
                      >
                        View List
                      </button>
                      <button
                        className="btn btn-primary btn-bid font-weight-light m-0"
                        disabled={new Date(name.expireAt) < new Date()}
                        onClick={() => {
                          bid(name);
                        }}
                      >
                        Raise Bid
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {Object.keys(selectedName).length > 0 && selectedName.list && (
            <BidList
              name={selectedName}
              setSelectedName={setSelectedName}
              currency={currency}
              notExpired={new Date(selectedName.expireAt) > new Date()}
            />
          )}
          {Object.keys(selectedName).length > 0 && selectedName.bid && (
            <Bid
              currency={currency}
              name={selectedName}
              setSelectedName={setSelectedName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TimerCountdown = ({ date }) => {
  let [expireAt, setExpireAt] = useState("");
  const bidExpiresIn = (date) => {
    let interval = setInterval(() => {
      let countDownDate = new Date(date).getTime();
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (seconds < -1) {
        clearInterval(interval);
        setExpireAt("EXPIRED");
      } else {
        setExpireAt(
          days + "d " + hours + "h " + minutes + "m " + seconds + "s "
        );
      }
    }, 1000);
  };
  useEffect(() => {
    bidExpiresIn(date);
  }, []);
  return <span>{expireAt}</span>;
};
