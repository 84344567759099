import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/app.css";
import "./assets/marketing.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReserveName } from "./Pages/reserveName";
import { TopBidName } from "./Pages/topBidName";
import { BidName } from "./Pages/bidName";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ReserveName />}></Route>
        <Route path="/top-bids-name" element={<BidName />}></Route>
        <Route path="*" element={<ReserveName />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
